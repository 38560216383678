import { sanitize } from "isomorphic-dompurify";
import { TextUtils } from "@/utils/TextUtils";
import styles from "./ProfileRichBody.module.scss";

type ProfileRichBodyProps = {
  render_html: boolean;
  text: string;
};

function ProfileRichBody({ render_html, text }: ProfileRichBodyProps) {
  if (render_html) {
    return (
      <div
        className={styles.container}
        dangerouslySetInnerHTML={{
          __html: sanitize(TextUtils.simpleFormat(text)),
        }}
      />
    );
  }

  return <div className={styles.container}>{text}</div>;
}

export default ProfileRichBody;
