import React from "react";
import styles from "./CollapsibleText.module.scss";
import { Flex } from "@radix-ui/themes";
import { TextProps, TextV2 } from "@/design-system/components/text/TextV2";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import { useTranslations } from "next-intl";
import classNames from "classnames";

interface CollapsibleTextProps extends TextProps {}

const CollapsibleText = (props: CollapsibleTextProps) => {
  const {
    textStyle = "Body L",
    ...otherProps
  } = props;
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const containerRef = React.useRef<HTMLSpanElement | null>(null);
  const t = useTranslations("Common");

  return (
    <Flex direction={"column"} gap={"3"}>
      <TextV2
        className={classNames(styles.textContainer, {[styles.isCollapsed]: isCollapsed})}
        ref={containerRef}
        textStyle={textStyle}
        {...otherProps}
      />

        <Flex
          className={classNames(styles.buttonContainer, {[styles.isHidden]: !isCollapsed})}
          direction={"column"}
          align={"start"}
        >
          <ButtonV2
            className={styles.button}
            onClick={() => setIsCollapsed(false)}
            variant="ghost"
            color={"gray"}
            size="3"
            mt={"1"}
          >
            {t("read_more")}
          </ButtonV2>
        </Flex>
    </Flex>
  );
};

export default CollapsibleText;
