import { Box, Flex, Skeleton, TabNav } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import styles from "./ProfileTabNavigation.module.scss";
import MonitoringService from "@/lib/MonitoringService";
import { useMediaQuery } from "react-responsive";
import { QUERIES } from "@/design-system/breakpoints";

type ProfileTabNavigationProps = {
  onClick: (event: React.MouseEvent<HTMLElement>, elementId: string) => void;
};

type Tab = {
  tabName: string;
  elementId: string;
};

function ProfileTabNavigation(props: ProfileTabNavigationProps) {
  const t = useTranslations("Profile");
  const [filteredTabs, setFilteredTabs] = useState<Tab[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true); // Add loading state
  const isMobile = useMediaQuery({ query: QUERIES.mobile });

  // Ensure DOM accessibility after the component mounts on the client
  useEffect(() => {
    const fetchTabs = async () => {
      try {
        const tabs = [
          { tabName: t("intro"), elementId: "intro" },
          { tabName: t("approach"), elementId: "approach" },
          { tabName: t("cost"), elementId: "cost" },
          { tabName: t("explore_more"), elementId: "explore-more" },
        ].filter((tab) => {
          // Check that the element linked exists in the DOM
          return document.getElementById(tab.elementId) !== null;
        });

        setFilteredTabs(tabs);
      } catch (error) {
        MonitoringService.captureException(error);
      } finally {
        setLoading(false);
      }
    };
    fetchTabs();
  }, [t]);

  useEffect(() => {
    const handleScroll = () => {
      let currentTabIndex = activeTab;
      // Iterate over each tab to determine which should be active
      filteredTabs.forEach((tab, index) => {
        const element = document.getElementById(tab.elementId);
        if (element) {
          const scrollPos = window.scrollY;
          const rect = element.getBoundingClientRect();
          const offset = tab.elementId === "explore-more" ? 24 : (isMobile ? 64 : 82);
          const sectionTop = rect.top + window.scrollY - offset;
          let sectionBottom;
          // Calculate sectionBottom as the top of the next section minus one pixel
          if (index < filteredTabs.length - 1) {
            const nextElement = document.getElementById(
              filteredTabs[index + 1].elementId,
            );
            if (nextElement) {
              const nextRect = nextElement.getBoundingClientRect();
              sectionBottom = nextRect.top + window.scrollY - 1;
            } else {
              sectionBottom = Infinity; // If there's no next section, set to Infinity
            }
          } else {
            sectionBottom = Infinity; // Last section; set bottom to Infinity
          }
          // Check if we have scrolled past the beginning of the section
          if (scrollPos >= sectionTop && scrollPos < sectionBottom) {
            currentTabIndex = index;
          }
        }
      });
      if (currentTabIndex !== activeTab) {
        setActiveTab(currentTabIndex);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [filteredTabs, activeTab, isMobile]);

  const onClick = (
    event: React.MouseEvent<HTMLElement>,
    activeTabIndex: number,
    elementId: string,
  ) => {
    setActiveTab(activeTabIndex);
    props.onClick(event, elementId);
  };

  return (
    <Box
      className={styles.tabNavigationContainer}
      px={{ initial: "5", sm: "0" }}
    >
      {loading ? (
        <Flex className={styles.tabNavigation}>
          <Skeleton height={"20px"} width={"40px"} my={"8px"} />
          <Skeleton height={"20px"} width={"90px"} my={"8px"} />
          <Skeleton height={"20px"} width={"60px"} my={"8px"} />
          <Skeleton height={"20px"} width={"100px"} my={"8px"} />
        </Flex>
      ) : (
        <TabNav.Root color={"gray"} className={styles.tabNavigation}>
          {filteredTabs.map((tab, tabIndex) => (
            <TabNav.Link
              key={tabIndex}
              className={styles.tabNavigationItem}
              active={activeTab === tabIndex}
              onClick={(e) => onClick(e, tabIndex, tab.elementId)}
            >
              {tab.tabName}
            </TabNav.Link>
          ))}
        </TabNav.Root>
      )}
    </Box>
  );
}

export default ProfileTabNavigation;
