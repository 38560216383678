import { profile as api } from "@/api/entities";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPaginationDots,
  CarouselPrevious,
} from "@/design-system/components/Carousel/Carousel";
import { useTranslations } from "next-intl";
import ProfileImage from "../Picture/ProfileImage";
import styles from "./ProfilePictureCarousel.module.scss";

type ProfileCarouselProps = { profile: api.Profile };

function ProfilePictureCarousel({ profile }: ProfileCarouselProps) {
  const t = useTranslations("Profile");
  const numberOfPictures = profile.pictures.original.length;

  return numberOfPictures > 0 ? (
    <div className={styles.container}>
      <Carousel className={styles.carousel}>
        <CarouselContent className={styles.carouselContent}>
          {profile.pictures.original.map((url, i) => (
            <CarouselItem key={i} className={styles.carouselItem}>
              <ProfileImage
                src={url}
                alt={t("images_alt.profile_image", {
                  fullName: profile.full_name,
                  jobTitle: profile.jobtitle,
                })}
                aspectRatio={1}
                className={styles.carouselImage}
                priority
              />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious
          className={styles.carouselPreviousButton}
          type={"button"}
        />
        <CarouselNext className={styles.carouselNextButton} type={"button"} />
        <CarouselPaginationDots className={styles.carouselPaginationDots} />
      </Carousel>
    </div>
  ) : (
    <div className={styles.container}>
      <ProfileImage
        key={"placeholder"}
        src={"/images/profile_picture_round_rect.svg"}
        alt={t("images_alt.profile_image", {
          fullName: profile.full_name,
          jobTitle: profile.jobtitle,
        })}
        aspectRatio={1}
        className={styles.carouselImage}
        priority
      />
    </div>
  );
}

export default ProfilePictureCarousel;
