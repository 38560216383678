import { profile as api } from "@/api/entities";
import { LinkIcon } from "@/design-system/components/LinkIcon/LinkIcon";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { TextV2 } from "@/design-system/components/text/TextV2";
import addressFormatter from "@fragaria/address-formatter";
import { Box, Flex, Section, Separator } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import ProfileRichBody from "../ProfileRichBody/ProfileRichBody";
import ProfileSocialMedias from "../ProfileSocialMedias/ProfileSocialMedias";
import CollapsibleText from "@/design-system/components/CollapsibleText/CollapsibleText";

type ProfileAboutMeProps = { profile: api.Profile };

function ProfileAboutMe({ profile }: ProfileAboutMeProps) {
  const t = useTranslations("Profile");
  const languages = profile.languages.map((language) => language.name);
  const formattedAddress = addressFormatter.format(
    {
      houseNumber: profile.number,
      road: profile.street,
      city: profile.city.name,
      postcode: profile.zip,
      country: profile.country,
    },
    {
      output: "array",
    },
  );

  return (
    <Box px={{ initial: "5", sm: "0" }}>
      <Section id="intro" size={"1"} p={"0"}>
        {/* Profile about me */}
        <Box pb={"5"}>
          <HeadingV2
            as={"h2"}
            textStyle={"Headline M"}
            weightStyle={"medium"}
            my={"5"}
          >
            {t("about_me")}
          </HeadingV2>
          {profile.about && (
            <CollapsibleText
              textStyle={"Body M"}
            >
              <ProfileRichBody
                render_html={profile.is_premium}
                text={profile.about}
              />
            </CollapsibleText>
          )}
        </Box>

        {/* Profile languages */}
        {languages.length > 0 && (
          <Box pb={"5"}>
            <HeadingV2
              as={"h3"}
              textStyle={"Headline S"}
              weightStyle={"medium"}
              mb={"3"}
            >
              {t("i_speak")}
            </HeadingV2>
            <Flex direction={"column"}>
              {languages.map((item, index) => (
                <TextV2 key={index} textStyle={"Body XL"}>
                  {item}
                </TextV2>
              ))}
            </Flex>
          </Box>
        )}

        {/* Profile social medias */}
        {profile.external_links.length > 0 && (
          <Box pb={"5"}>
            <HeadingV2
              as={"h3"}
              textStyle={"Headline S"}
              weightStyle={"medium"}
              mb={"3"}
            >
              {t("connect_with_me")}
            </HeadingV2>
            <ProfileSocialMedias profile={profile} />
          </Box>
        )}

        {/* Profile location */}
        <Box>
          <HeadingV2
            as={"h3"}
            textStyle={"Headline S"}
            weightStyle={"medium"}
            mb={"3"}
          >
            {t("location")}
          </HeadingV2>
          <Flex direction={"column"}>
            <TextV2 textStyle={"Body XL"}>{formattedAddress.join(", ")}</TextV2>
            <LinkIcon
              iconType={"arrow"}
              text={t("open_in_google_maps")}
              rel={"nofollow noopener noreferrer"}
              href={`https://www.google.com/maps?q=${profile.lat},${profile.lng}`}
            />
          </Flex>
        </Box>
      </Section>

      <Separator
        orientation={"horizontal"}
        style={{ backgroundColor: "var(--colorV2-grey-medium-dark)" }}
        size={"4"}
        mt={{ initial: "6", sm: "8" }}
      />
    </Box>
  );
}

export default ProfileAboutMe;
