import { profile as api } from "@/api/entities";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { Box, Flex, Popover, Section, Tooltip } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import { useEffect, useRef, useState } from "react";
import styles from "./ProfileSpecialties.module.scss";

type ProfileSpecialtiesProps = { profile: api.Profile };

function ProfileSpecialties({ profile }: ProfileSpecialtiesProps) {
  const t = useTranslations("Profile");
  const [openDialogId, setOpenDialogId] = useState<number | null>(null);
  const triggerRefs = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    const observers = triggerRefs.current.map((trigger) => {
      if (!trigger) return null;
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Close popover when scrolling past it
          if (!entry.isIntersecting && openDialogId !== null) {
            setOpenDialogId(null);
          }
        },
        { threshold: 0 },
      );
      observer.observe(trigger);
      return observer;
    });
    return () => {
      observers.forEach((observer) => observer?.disconnect());
    };
  }, [openDialogId]);

  return (
    profile.specialities &&
    profile.specialities.length > 0 && (
      <Box px={{ initial: "5", sm: "0" }}>
        <Section size={"1"} p={"0"}>
          <HeadingV2
            as={"h3"}
            textStyle={"Headline XS"}
            weightStyle={"medium"}
            mb={{ initial: "3", sm: "4" }}
          >
            {t("i_specialize_in")}
          </HeadingV2>
          <Flex gap={"2"} wrap={"wrap"}>
            {profile.specialities.map((specialty, specialtyIndex) => (
              <Box key={specialtyIndex}>
                <Popover.Root
                  open={openDialogId === specialtyIndex}
                  onOpenChange={(open) => {
                    if (!open) setOpenDialogId(null);
                  }}
                >
                  <Tooltip
                    className={styles.tooltip}
                    content={t("what_is_this")}
                  >
                    <Popover.Trigger>
                      <ButtonV2
                        ref={(el) => {
                          triggerRefs.current[specialtyIndex] = el;
                        }}
                        className={styles.button}
                        color="mint"
                        variant="outline"
                        size={{ initial: "2", sm: "3" }}
                        highContrast={false}
                        onClick={() => setOpenDialogId(specialtyIndex)}
                      >
                        {specialty.name}
                      </ButtonV2>
                    </Popover.Trigger>
                  </Tooltip>

                  <Popover.Content
                    aria-describedby={undefined}
                    size={"3"}
                    maxWidth={"312px"}
                    width={{ initial: "80vw", sm: "312px" }}
                    onCloseAutoFocus={(event) => {
                      // Prevents scrolling to the trigger element after closing popover
                      event.preventDefault();
                    }}
                  >
                    <Flex direction={"column"} gap={"4"}>
                      <TextV2
                        className={styles.title}
                        textStyle={"Headline XS"}
                        weightStyle={"medium"}
                      >
                        {specialty.name}
                      </TextV2>

                      <TextV2 textStyle={"Body S"} weightStyle={"regular"}>
                        {specialty.excerpt}
                      </TextV2>
                    </Flex>
                  </Popover.Content>
                </Popover.Root>
              </Box>
            ))}
          </Flex>
        </Section>

        <Box mt={"5"} />
      </Box>
    )
  );
}

export default ProfileSpecialties;
