import { Profile } from "@/api/entities/profile";
import { useTherapistInquiry } from "@/contexts/TherapistInquiryContext";
import { FullWidthPrimaryButton } from "@/design-system/components/PrimaryButton";
import { Box } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import React from "react";
import ProfileResponseTimeMessage from "../ProfileResponseTimeMessage/ProfileResponseTimeMessage";
import styles from "./ProfileStickyBookingInfo.module.scss";
import moment from "moment";
import { TextV2 } from "@/design-system/components/text/TextV2";

function ProfileStickyBookingInfo({
  profile,
  upcomingAvailabilitySlots,
}: {
  profile: Profile;
  upcomingAvailabilitySlots: string[];
}) {
  const { handleSendMessage } = useTherapistInquiry();
  const t = useTranslations("Profile");
  const responseTime = profile.response_time;
  const nextAvailability =
    upcomingAvailabilitySlots.length > 0 &&
    profile.calendar_enabled &&
    profile.is_available
      ? upcomingAvailabilitySlots[0]
      : undefined;
  const nextAvailabilityDate = nextAvailability
    ? moment(nextAvailability).format("MMM D") // e.g., "Sep 20"
    : undefined;

  return (
    <Box className={styles.container} px={"5"} py={"2"}>
      <FullWidthPrimaryButton
        onClick={() => {
          handleSendMessage("contact_button");
        }}
      >
        {t("send_message", { name: profile.firstname })}
      </FullWidthPrimaryButton>
      {nextAvailabilityDate ? (
        <Box py={"1"}>
          <TextV2 textStyle={"Body M"}>
            {t("next_available", { nextAvailabilityDate })}
          </TextV2>
        </Box>
      ) : (
        !!responseTime && (
          <Box py={"1"}>
            <ProfileResponseTimeMessage responseTime={responseTime} />
          </Box>
        )
      )}
    </Box>
  );
}

export { ProfileStickyBookingInfo };
