export class TextUtils {
  static simpleFormat(text: string): string {
    if (!text) {
      return "";
    }

    // Convert URLs to hyperlinks
    const linkedText = text.replace(
      /((?:https?|ftp):\/\/[^\s]+)/g,
      '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>',
    );

    // Replace newlines with HTML line breakz
    let formattedText = linkedText.replace(/\\r/g, "<br />");
    formattedText = formattedText.replace(/\\n/g, "<br />");
    formattedText = formattedText.replace(/\\r\\n/g, "<br />");
    formattedText = formattedText.replace(/(?:\r\n|\r|\n)/g, "<br />");

    return formattedText;
  }
}
