import { profile as api } from "@/api/entities";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { Box, Flex, Section } from "@radix-ui/themes";
import { Armchair, HandHeart } from "lucide-react";
import { useFormatter, useTranslations } from "next-intl";
import ProfileIconBadges from "../ProfileIconBadges/ProfileIconBadges";
import { ProfilePriceOffers } from "../ProfilePriceOffers/ProfilePriceOffers";

type InsuranceTranslationKeys = Messages["Profile"]["insurance_types"];

type ProfilePriceDetailsProps = { profile: api.Profile };

function ProfilePriceDetails({ profile }: ProfilePriceDetailsProps) {
  const t = useTranslations("Profile");
  const tInsurances = useTranslations("Profile.insurance_types");
  const format = useFormatter();

  const freeConsultationText = profile.free_initial_session_offer
    ? profile.free_initial_session_offer.duration
      ? t("badges.free_consultation_duration", {
          duration: profile.free_initial_session_offer.duration,
        })
      : t("badges.free_consultation")
    : undefined;
  const slidingScaleText = profile.sliding_scale_session_offer
    ? t("badges.sliding_scale", {
        sessionPrice: format.number(
          profile.sliding_scale_session_offer.price,
          {
            style: "currency",
            currency: profile.price_currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          },
        ),
      })
    : undefined;
  const priceBadges = [
    { icon: <Armchair size={18} />, text: freeConsultationText },
    { icon: <HandHeart size={18} />, text: slidingScaleText },
  ].filter((item) => item.text !== undefined);

  const insurances = profile.insurances
    .map((insurance) => insurance.slug)
    .map((slug) => {
      return tInsurances(`${slug}` as keyof InsuranceTranslationKeys);
    })
    .filter((insurance) => insurance !== "");

  return (
    <Box px={{ initial: "5", sm: "0" }}>
      <Section id="cost" size={"1"} p={"0"}>
        <Box>
          <HeadingV2
            as={"h2"}
            textStyle={"Headline M"}
            weightStyle={"medium"}
            my={"5"}
          >
            {t("cost")}
          </HeadingV2>

          {/* Profile price badges */}
          <ProfileIconBadges badges={priceBadges} />

          {/* Profile price offers */}
          <ProfilePriceOffers profile={profile} />

          {/* Profile insurances */}
          {insurances.length > 0 && (
            <Box>
              <HeadingV2
                as={"h3"}
                textStyle={"Headline S"}
                weightStyle={"medium"}
                mb={"1"}
              >
                {t("insurances")}
              </HeadingV2>
              <Flex direction={"column"}>
                {insurances.map((item, index) => (
                  <TextV2 key={index} textStyle={"Body XL"}>
                    {item}
                  </TextV2>
                ))}
              </Flex>
            </Box>
          )}
        </Box>
      </Section>
    </Box>
  );
}

export default ProfilePriceDetails;
