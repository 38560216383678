import { profile as api } from "@/api/entities";
import FavoriteButton from "@/components/Favorites/FavoriteButton";
import GreenTag from "@/design-system/components/GreenTag";
import RedTag from "@/design-system/components/RedTag";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { Box, Flex } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import ProfileRichBody from "../ProfileRichBody/ProfileRichBody";
import styles from "./ProfileBasicInfo.module.scss";
import ProfilePictureCarousel from "../ProfilePictureCarousel/ProfilePictureCarousel";

type PronounTranslationKeys = Messages["Profile"]["pronouns"];

type ProfileBasicInfoProps = { profile: api.Profile };

function ProfileBasicInfo({ profile }: ProfileBasicInfoProps) {
  const tPronouns = useTranslations("Profile.pronouns");

  return (
    <>
      {/* Profile picture carousel */}
      <Box maxWidth={{ initial: "100%", sm: "400px" }} position={"relative"}>
        <ProfilePictureCarousel profile={profile} />
        <Box
          display={{ initial: "block", sm: "none" }}
          position={"absolute"}
          top={"18px"}
          right={"18px"}
        >
          <FavoriteButton
            therapist_user_id={profile.user.id}
            picture_url={profile.profile_picture_url}
            jobtitle={profile.jobtitle}
            full_name={profile.full_name}
            therapist_first_name={profile.firstname}
            currentPage="therapist_profile_page"
            isCTA={true}
          />
        </Box>
      </Box>

      <Box px={{ initial: "5", sm: "0" }}>
        {/* Profile name, pronouns, job title */}
        <Box pt={{ initial: "4", sm: "6" }} pb={{ initial: "4", sm: "5" }}>
          <Flex gap={"2"} align={"baseline"} pb={"1"} wrap={"wrap"}>
            <Box flexShrink={"0"}>
              <HeadingV2
                as={"h1"}
                textStyle={"Headline L"}
                weightStyle={"medium"}
                className={styles.fullName}
              >
                {profile.full_name}
              </HeadingV2>
            </Box>

            {profile.pronouns && (
              <Box flexShrink={"0"}>
                <TextV2 textStyle={"Headline M"} color={"gray"}>
                  {`(${
                    profile.custom_pronouns && profile.pronouns === "custom"
                      ? profile.custom_pronouns
                      : tPronouns(
                          `${profile.pronouns}` as keyof PronounTranslationKeys,
                        )
                  })`}
                </TextV2>
              </Box>
            )}
          </Flex>
          <HeadingV2 as={"h2"} textStyle={"Headline M"} color={"gray"}>
            {profile.jobtitle}
          </HeadingV2>
        </Box>

        {/* Profile availability tag */}
        <Box pb={{ initial: "4", sm: "5" }}>
          <ProfileAvailabilityTag available={profile.is_available} />
        </Box>

        {/* Profile professional statement */}
        {profile.professional_statement && (
          <Box pb={{ initial: "4", sm: "5" }}>
            <ProfileRichBody
              render_html={profile.is_premium}
              text={profile.professional_statement}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

function ProfileAvailabilityTag({ available }: { available: boolean }) {
  const t = useTranslations("Profile");

  return available ? (
    <GreenTag isLarge>{t("available_for_new_clients")}</GreenTag>
  ) : (
    <RedTag isLarge>{t("unavailable_for_new_clients")}</RedTag>
  );
}

export default ProfileBasicInfo;
