"use client";
import React from "react";
import dynamic from "next/dynamic";
import AsyncChunkErrorBoundary from "../Errors/AsyncChunkErrorBoundary";
import { BaseReactPlayerProps } from "react-player/base";

const ReactPlayer = dynamic(() => import("react-player"), {
  ssr: false,
});

export const ReactPlayerWrapper: React.FC<BaseReactPlayerProps> = (
  props: BaseReactPlayerProps,
) => {
  return (
    <AsyncChunkErrorBoundary>
      <ReactPlayer {...props} />
    </AsyncChunkErrorBoundary>
  );
};
