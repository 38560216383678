import { profile as api } from "@/api/entities";
import { Flex, IconButton, Link } from "@radix-ui/themes";
import { Globe } from "lucide-react";
import Image from "next/image";
import instagramImage from "/public/icons/instagram.svg";
import linkedinImage from "/public/icons/linkedin.svg";
import mediumImage from "/public/icons/medium.svg";
import spotifyImage from "/public/icons/spotify.svg";
import tiktokImage from "/public/icons/tiktok.svg";
import youtubeImage from "/public/icons/youtube.svg";
import styles from "./ProfileSocialMedias.module.scss";

type ProfileSocialMediasProps = { profile: api.Profile };

function ProfileSocialMedias({ profile }: ProfileSocialMediasProps) {
  return (
    <Flex gap={"4"}>
      {profile.external_links.map((item, index) => (
        <Link key={index} target={"_blank"} rel={"nofollow"} href={item.url}>
          <IconButton
            key={index}
            className={styles.socialMediaIcon}
            radius="full"
            size={"3"}
            color={"gray"}
            variant={"soft"}
            highContrast
          >
            {item.url_type === "instagram" && (
              <Image
                src={instagramImage}
                width={18}
                height={18}
                priority={true}
                alt={item.url_type}
              />
            )}
            {item.url_type === "linkedin" && (
              <Image
                src={linkedinImage}
                width={18}
                height={18}
                priority={true}
                alt={item.url_type}
              />
            )}
            {item.url_type === "medium" && (
              <Image
                src={mediumImage}
                width={18}
                height={18}
                priority={true}
                alt={item.url_type}
              />
            )}
            {item.url_type === "spotify" && (
              <Image
                src={spotifyImage}
                width={18}
                height={18}
                priority={true}
                alt={item.url_type}
              />
            )}
            {item.url_type === "tiktok" && (
              <Image
                src={tiktokImage}
                width={18}
                height={18}
                priority={true}
                alt={item.url_type}
              />
            )}
            {item.url_type === "youtube" && (
              <Image
                src={youtubeImage}
                width={18}
                height={18}
                priority={true}
                alt={item.url_type}
              />
            )}
            {item.url_type === "website" && <Globe size={18} />}
          </IconButton>
        </Link>
      ))}
    </Flex>
  );
}

export default ProfileSocialMedias;
