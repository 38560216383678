import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./RedTag.module.scss";

type Props = {
  children: ReactNode;
  isLarge?: boolean;
};

function RedTag({ children, isLarge }: Props) {
  return (
    <div className={classNames(styles.redTag, {[styles.isLarge]: isLarge})}>{ children }</div>
  );
}

export default RedTag;
