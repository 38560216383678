import styled from "styled-components";
import NextImage from "next/image";
import { useEffect, useState } from "react";

interface ProgressiveImageProps {
  lowResUrl: string | null;
  highResUrl: string | null;
  alt: string;
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledImage = styled(NextImage)`
  object-fit: contain;
`;

const ProgressiveImage: React.FC<ProgressiveImageProps> = ({
  lowResUrl,
  highResUrl,
  alt,
}) => {
  const [isHighResImageLoaded, setIsHighResImageLoaded] =
    useState<boolean>(false);

  useEffect(() => {
    if (highResUrl) {
      const img = new window.Image();
      img.src = highResUrl;
      img.onload = () => {
        setIsHighResImageLoaded(true);
      };
      img.onerror = () => {
        setIsHighResImageLoaded(false);
      };
    }
  }, [highResUrl]);

  return (
    <Wrapper>
      {lowResUrl && (
        <ImageContainer className={isHighResImageLoaded ? "" : "loaded"}>
          <StyledImage
            src={lowResUrl}
            alt={alt}
            unoptimized
            sizes="100%"
            fill
            quality={1}
            priority
          />
        </ImageContainer>
      )}
      {highResUrl && (
        <ImageContainer className={isHighResImageLoaded ? "loaded" : ""}>
          <StyledImage
            src={highResUrl}
            alt={alt}
            sizes="100%"
            fill
            quality={100}
          />
        </ImageContainer>
      )}
    </Wrapper>
  );
};

export default ProgressiveImage;
