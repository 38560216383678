// extracted from https://github.com/Complicated-Life/Its-Complicated/blob/4857f467eab4d73b204061871779c16e892c32af/client/src/app/components/messaging/service/crypto.service.ts
async function encrypt(body: string, key: string) {
  if (!window || !window.crypto) {
    throw new Error("Missing crypto capabilities");
  }

  const message = encodeEmoji(body);
  const keyDigest = await crypto.subtle.digest(
    { name: "SHA-256" },
    new TextEncoder().encode(key),
  );
  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    keyDigest,
    "AES-CBC",
    false,
    ["encrypt", "decrypt"],
  );
  const encryptedMessage = await crypto.subtle.encrypt(
    { name: "AES-CBC", iv: digestVector(key) },
    cryptoKey,
    new TextEncoder().encode(message),
  );
  return buf2hex(new Uint8Array(encryptedMessage));
}

function encodeEmoji(content: string) {
  return decodeURIComponent(encodeURIComponent(content));
}

function digestVector(key: string) {
  const ivstr = key.substring(0, 8);
  return new TextEncoder().encode(ivstr + ivstr);
}

function buf2hex(buffer: Uint8Array) {
  return Array.prototype.map
    .call(new Uint8Array(buffer), (x) => ("00" + x.toString(16)).slice(-2))
    .join("");
}

export { encrypt };
