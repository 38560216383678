import { profile as api } from "@/api/entities";
import { JsonLd } from "react-schemaorg";
import { Person } from "schema-dts";

type ProfilePersonLinkedDataProps = { profile: api.Profile };

function ProfilePersonLinkedData({ profile }: ProfilePersonLinkedDataProps) {
  const image =
    profile.pictures.original.length > 0
      ? profile.pictures.original[0]
      : undefined;

  return (
    <JsonLd<Person>
      item={{
        "@context": "https://schema.org",
        "@type": "Person",
        url: profile.link,
        image,
        name: profile.full_name,
        jobTitle: profile.jobtitle,
      }}
    />
  );
}

export { ProfilePersonLinkedData };
