import moment from "moment";

function convertUTCTimeslotsToAvailabilityByDay(
  slots: string[],
): UpcomingAvailabilityByDay {
  const localSlots: Record<ISODate, TimeSlot[]> = {};

  slots.forEach((slot) => {
    const localTimeMoment = moment.utc(slot).local();
    const formattedTime = localTimeMoment.format("HH:mm");

    // Truncate to the beginning of today (do not use toISOString() as it will convert to UTC (causes COM-2344)
    const day = localTimeMoment
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    if (!localSlots[day]) {
      localSlots[day] = [];
    }
    localSlots[day].push({
      startUTCFormatted: slot,
      startLocalFormatted: formattedTime,
    });
  });

  return Object.entries(localSlots);
}

type ISODate = string;
type TimeSlot = {
  startUTCFormatted: string;
  startLocalFormatted: string;
};
type UpcomingAvailabilityByDay = [ISODate, TimeSlot[]][];

export { convertUTCTimeslotsToAvailabilityByDay };
export type { TimeSlot, ISODate, UpcomingAvailabilityByDay };
