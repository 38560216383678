import { TextV2 } from "@/design-system/components/text/TextV2";
import { convertUTCTimeslotsToAvailabilityByDay } from "@/utils/TimeUtils";
import { Box, Flex } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import { useMemo } from "react";
import AvailabilityDayWithSlots from "./AvailabilityDayWithSlots";
import styles from "./AvailabilityCarousel.module.scss";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/design-system/components/Carousel/Carousel";

interface AvailabilityCarouselProps {
  upcomingAvailabilitySlots: string[];
  locale?: string;
}

export default function AvailabilityCarousel({
  upcomingAvailabilitySlots,
  locale,
}: AvailabilityCarouselProps) {
  const t = useTranslations("Profile");
  const upcomingAvailability = useMemo(
    () => convertUTCTimeslotsToAvailabilityByDay(upcomingAvailabilitySlots),
    [upcomingAvailabilitySlots],
  );

  if (!upcomingAvailability || upcomingAvailability.length === 0) {
    return null;
  }

  return (
    <Flex
      className={styles.availabilityCarouselContainer}
      direction={"column"}
      width={"100%"}
    >
      <TextV2
        textStyle={"Headline M"}
        weightStyle={"medium"}
        m={"auto"}
        mb={"5"}
      >
        {t("availability")}
      </TextV2>
      <Box px={"8"} pb={"2"} minWidth={"0"}>
        <Carousel className={styles.carousel} slidesToScroll={3}>
          <CarouselContent className={styles.carouselContent}>
            {upcomingAvailability.map(([day, slots]) => (
              <CarouselItem key={day} className={styles.carouselItem}>
                <AvailabilityDayWithSlots
                  isoDate={day}
                  slots={slots}
                  locale={locale}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious
            className={styles.carouselPreviousButton}
            isGreenLayout
            type={"button"}
          />
          <CarouselNext
            className={styles.carouselNextButton}
            isGreenLayout
            type={"button"}
          />
        </Carousel>
      </Box>
    </Flex>
  );
}
