import { encrypt } from "@/utils/CryptoUtils";
import { render, renderValue, ServerResponse } from "./api";
import { ProfileUser } from "./entities/profile";

type CreateMessageRes = { conversationId: string; isNewConversation: boolean };

async function createMessage(
  recipientUserId: ProfileUser["id"],
  message: string,
  appointmentInquiry?: { timestamp: number },
): Promise<ServerResponse<CreateMessageRes>> {
  const startConversationRes = await startConversation(recipientUserId);
  if (
    startConversationRes.status === "error" ||
    startConversationRes.status === "aborted"
  ) {
    return startConversationRes;
  }

  const conversationId = startConversationRes.value.conversation.id;
  const isNewConversation = startConversationRes.statusCode === 201;
  const encryptedMessage = await encrypt(
    message,
    startConversationRes.value.conversation.key,
  );
  const messageRes = await creeateMessage(
    startConversationRes.value.conversation.id,
    recipientUserId,
    encryptedMessage,
    appointmentInquiry,
  );
  return messageRes.status === "ok"
    ? renderValue(messageRes.statusCode, { conversationId, isNewConversation })
    : messageRes;
}

type StartConversationValue = { conversation: { id: string; key: string } };

async function startConversation(
  recipientUserId: ProfileUser["id"],
): Promise<ServerResponse<StartConversationValue>> {
  return await render(
    fetch(`/api/conversation/start`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipient_user_id: recipientUserId,
      }),
    }),
  );
}

async function creeateMessage(
  conversationId: string,
  recipientUserId: ProfileUser["id"],
  encryptedMessage: string,
  appointmentInquiry?: { timestamp: number },
): Promise<ServerResponse<void>> {
  return await render(
    fetch(`/api/conversation/message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        conversation_id: conversationId,
        recipient_user_id: recipientUserId,
        encrypted_message: encryptedMessage,
        appointment_inquiry: appointmentInquiry,
      }),
    }),
  );
}

export { createMessage };
