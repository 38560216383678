import { pushDataLayerEventAsync } from "@/analytics/analytics.datalayer";
import { createMessage } from "@/api/api.messages";
import { profile as profileApi } from "@/api/entities";
import { useUserSession } from "@/contexts/UserSession";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import { navigateToDashboardAfterInquiry } from "@/helpers/AngularDashboardNavigator";
import { useSupportedLocale } from "@/hooks/useLocale";
import {
  convertUTCTimeslotsToAvailabilityByDay,
  UpcomingAvailabilityByDay,
} from "@/utils/TimeUtils";
import { useFavorites } from "@components/Favorites/FavoritesContext";
import * as Dialog from "@radix-ui/react-dialog";
import { Box, Flex, Skeleton, Theme, VisuallyHidden } from "@radix-ui/themes";
import { X } from "lucide-react";
import { useTranslations } from "next-intl";
import { useSearchParams } from "next/navigation";
import React, { useMemo } from "react";
import { ProfileContactAuth } from "./ProfileContactAuth";
import styles from "./ProfileContactDialog.module.scss";
import { ProfileContactHeader } from "./ProfileContactHeader";
import { ProfileContactMessage } from "./ProfileContactMessage";
import { directory } from "@/analytics";

interface ProfileContactDialogProps {
  profile: profileApi.Profile;
  upcomingAvailabilitySlots: string[];
  isOpen: boolean;
  selectedDatetime?: string;
  setOpen: (isOpen: boolean) => void;
  setSelectedDatetime: (datetime?: string) => void;
}

function ProfileContactDialog({
  profile,
  upcomingAvailabilitySlots,
  isOpen,
  selectedDatetime,
  setOpen,
  setSelectedDatetime,
}: ProfileContactDialogProps) {
  const locale = useSupportedLocale();
  const t = useTranslations("Profile.contact");
  const { session, isLoadingSession } = useUserSession();
  const [state, setState] = React.useState<
    "loading" | "authenticate" | "message"
  >("message");
  const [isSending, setIsSending] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const { isAFavorite } = useFavorites();

  const upcomingAvailability = useMemo(
    () => convertUTCTimeslotsToAvailabilityByDay(upcomingAvailabilitySlots),
    [upcomingAvailabilitySlots],
  );

  const handleSend = async (
    message: string,
    appointmentUTCTimestamp: number | undefined,
  ) => {
    directory.therapistInquirySubmitted({
      source_page: "therapist_profile_page",
      source_flow: "contact",
      appointment_selected: !!appointmentUTCTimestamp,
      appointments_available:
        profile.calendar_enabled &&
        profile.is_available &&
        upcomingAvailability.length > 0,
      therapist_user_id: profile.user.id.toString(),
      has_active_video: profile.has_active_video,
      is_a_favorite: isAFavorite(profile.user.id.toString()),
    });

    if (!session.user) {
      setState("authenticate");
      return;
    }

    setIsError(false);
    setIsSending(true);
    const res = await createMessage(
      profile.user.id,
      message,
      appointmentUTCTimestamp
        ? {
            timestamp: appointmentUTCTimestamp,
          }
        : undefined,
    );

    if (res.status === "ok") {
      if (res.value.isNewConversation) {
        await pushDataLayerEventAsync("first_message_sent");
      }

      directory.messageSent({
        therapist_user_id: profile.user.id.toString(),
        is_first_message: res.value.isNewConversation,
        conversation_id: res.value.conversationId,
        has_active_video: profile.has_active_video,
        is_a_favorite: isAFavorite(profile.user.id.toString()),
        therapist_display_city_slug: profile.city.slug,
      });
      await navigateToDashboardAfterInquiry(locale);
    } else {
      setIsSending(false);
      setIsError(true);
    }
  };

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      // When the dialog is closed, reset the state
      if (isLoadingSession) {
        setState("loading");
      } else {
        setState("message");
      }
      setSelectedDatetime(undefined);
    }
  };

  React.useEffect(() => {
    // set state to message when we have a valid session user
    if (isLoadingSession) {
      setState("loading");
    } else {
      setState("message");
    }
  }, [session.user, isLoadingSession]);

  const searchParams = useSearchParams();
  React.useEffect(() => {
    // Only open when the component is mounting client-side to work
    // around an hydration issue with Radix.
    // https://github.com/radix-ui/primitives/issues/1386
    const showContact = searchParams?.get("contact") === "1";
    if (!isOpen && showContact) {
      setOpen(true);
      directory.therapistInquiryInitiated(
        {
          therapist_user_id: profile.user.id.toString(),
          source_page: "therapist_profile_page",
          source_flow: "contact",
          source_action: "deeplink",
          has_active_video: profile.has_active_video,
          is_a_favorite: isAFavorite(profile.user.id.toString()),
        },
        searchParams,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (selectedDatetime) {
      if (!message && selectedDatetime) {
        setMessage(
          t("default_message_for_appointment", { name: profile.firstname }),
        );
      }
    } else {
      setMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when selectedDateTime changes
  }, [selectedDatetime]);

  // If we know this user is in the EAP program and the therapist is now, show the warning that they may have to pay
  const showEAPWarning: boolean = !!(
    session &&
    session.user &&
    !session.user.cannot_book_eap_session_reason &&
    !profile.active_in_eap_program
  );

  return (
    <div>
      <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
        <Dialog.Portal>
          <Theme>
            <Dialog.Overlay className={styles.dialogOverlay} />
            <Dialog.Content aria-describedby={undefined}>
              <VisuallyHidden>
                <Dialog.Title></Dialog.Title>
              </VisuallyHidden>

              <Flex direction={"column"} className={styles.dialogContent}>
                <Flex className={styles.dialogHeader} p={"3"}>
                  <ProfileContactHeader
                    picture_url={profile.profile_picture_url}
                    full_name={profile.full_name}
                    jobtitle={profile.jobtitle}
                  />
                  <Flex justify={"end"}>
                    <Dialog.Close asChild>
                      <ButtonV2 radius="full" color={"gray"} variant={"ghost"}>
                        <X size={40} strokeWidth={"1"} />
                      </ButtonV2>
                    </Dialog.Close>
                  </Flex>
                </Flex>
                {isError && (
                  <Box className={styles.dialogError} mt={"3"} p={"3"}>
                    {t("generic_error")}
                  </Box>
                )}
                <Flex
                  direction={"column"}
                  minHeight={"250px"}
                  width={{ initial: "100%", sm: "auto" }}
                  height={{ initial: "100%", sm: "auto" }}
                >
                  {state === "loading" && <Loading />}
                  {state === "authenticate" && (
                    <Authenticate profile={profile} />
                  )}
                  {state === "message" && (
                    <Message
                      profile={profile}
                      upcomingAvailability={upcomingAvailability}
                      isBusy={isSending}
                      message={message}
                      setMessage={setMessage}
                      selectedDatetime={selectedDatetime}
                      setSelectedDatetime={setSelectedDatetime}
                      showEAPWarning={showEAPWarning}
                      onSend={handleSend}
                    />
                  )}
                </Flex>
              </Flex>
            </Dialog.Content>
          </Theme>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}

function Loading() {
  return (
    <Box p={"5"}>
      <Skeleton width="100%" height="200px" />
    </Box>
  );
}

function Authenticate({ profile }: { profile: profileApi.Profile }) {
  return (
    <Box py={"4"}>
      <ProfileContactAuth
        user_id={profile.user.id.toString()}
        name={profile.firstname}
      />
    </Box>
  );
}

function Message({
  profile,
  upcomingAvailability,
  isBusy,
  message,
  setMessage,
  selectedDatetime,
  setSelectedDatetime,
  showEAPWarning,
  onSend,
}: {
  profile: profileApi.Profile;
  upcomingAvailability: UpcomingAvailabilityByDay;
  isBusy: boolean;
  message: string;
  setMessage: (message: string) => void;
  selectedDatetime: string | undefined;
  setSelectedDatetime: (datetime: string) => void;
  showEAPWarning: boolean;
  onSend: (
    message: string,
    appointmentUTCTimestamp: number | undefined,
  ) => void;
}) {
  return (
    <ProfileContactMessage
      profile={profile}
      upcomingAvailabilitySlots={upcomingAvailability}
      isBusy={isBusy}
      message={message}
      setMessage={setMessage}
      selectedDatetime={selectedDatetime}
      showEAPWarning={showEAPWarning}
      setSelectedDatetime={setSelectedDatetime}
      onSend={onSend}
    />
  );
}

export { ProfileContactDialog };
