import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { AlertDialog, Flex } from "@radix-ui/themes";

type AlertProps = {
  title: string;
  message: string;
  cta?: string;
  onCtaClick?: () => void;
  cancel?: string;
  onCancel?: () => void;
};

function Alert({
  title,
  message,
  cta,
  onCtaClick,
  cancel,
  onCancel,
}: AlertProps) {
  return (
    <AlertDialog.Root defaultOpen>
      <AlertDialog.Content style={{ maxWidth: 450 }}>
        <AlertDialog.Title>
          <TextV2 textStyle={"Headline L"}>{title}</TextV2>
        </AlertDialog.Title>
        <AlertDialog.Description size="2">
          <TextV2 textStyle={"Body M"}>{message}</TextV2>
        </AlertDialog.Description>
        <Flex gap="3" mt="4" justify="end">
          {cancel && (
            <AlertDialog.Cancel>
              <ButtonV2
                variant="soft"
                color="gray"
                onClick={() => {
                  if (onCancel) {
                    onCancel();
                  }
                }}
              >
                {cancel}
              </ButtonV2>
            </AlertDialog.Cancel>
          )}
          {cta && (
            <AlertDialog.Action>
              <ButtonV2
                onClick={() => {
                  if (onCtaClick) {
                    onCtaClick();
                  }
                }}
              >
                {cta}
              </ButtonV2>
            </AlertDialog.Action>
          )}
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}

export { Alert };
