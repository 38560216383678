import { useTranslations } from "next-intl";
import { Link as RadixLink } from "@radix-ui/themes/dist/cjs/components/link";
import React from "react";
import { Header } from "@components/Header/Header";
import ClientSideLink from "@/design-system/components/ClientSideLink";

function MarketingHeader({
  hideSessionInfo,
  hideLocalePicker,
  showTitleSubtext,
  breadcrumbs,
}: {
  hideSessionInfo?: boolean;
  hideLocalePicker?: boolean;
  showTitleSubtext?: boolean;
  breadcrumbs?: React.ReactNode;
}) {
  const t = useTranslations("Navigation");
  return (
    <Header
      navigationItems={[
        {
          node: (
            <RadixLink href={"https://product.complicated.life/for-therapists"}>
              {t("for_therapists")}
            </RadixLink>
          ),
        },
        {
          node: (
            <RadixLink href={"https://product.complicated.life/for-companies"}>
              {t("for_companies")}
            </RadixLink>
          ),
        },
        {
          node: (
            <ClientSideLink href={"/support/faq"}>{t("faq")}</ClientSideLink>
          ),
        },
        {
          node: (
            <RadixLink href="https://complicated.life/blog/">
              {t("blog")}
            </RadixLink>
          ),
        },
      ]}
      hideSessionInfo={hideSessionInfo}
      hideLocalePicker={hideLocalePicker}
      showTitleSubtext={showTitleSubtext}
      breadcrumbs={breadcrumbs}
    />
  );
}

export { MarketingHeader };
