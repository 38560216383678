import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./GreenTag.module.scss";

type Props = {
  children: ReactNode;
  isLarge?: boolean;
};

function GreenTag({ children, isLarge }: Props) {
  return (
    <div className={classNames(styles.greenTag, {[styles.isLarge]: isLarge})}>{ children }</div>
  );
}

export default GreenTag;
