import { Attachment } from "@/api/entities/attachment";
import { Category } from "@/api/server-only/server.api.profile.edit.categories";

type ProfileUser = {
  id: string;
  external_id: string;
  updated_at: string; // remove this after deploying the backend to have last_activity_at
  last_activity_at: string;
  email: string;
};

type ProfileAvailabilitySlots = string[]; /* slot timestamps */
type ProfileAvailability = Record<
  string /* day timestamp */,
  ProfileAvailabilitySlots
>;

enum SessionOfferType {
  Individual = "individual",
  Couples = "couples",
  Family = "family",
  Group = "group",
  Custom = "custom",
  SlidingScale = "sliding_scale",
  Initial = "initial",
}

type PrimarySessionOffer = {
  id?: string;
  price: number;
  currency?: string;
  duration?: string;
  session_type: SessionOfferType;
};

type SlidingScaleSessionOffer = {
  id?: string;
  price: number;
};

type FreeInitialSessionOffer = {
  id?: string;
  duration?: string;
};

type SecondarySessionOffer = {
  id?: string;
  price: number;
  currency?: string;
  duration?: string;
  title?: string;
  session_type: SessionOfferType;
};

type ProfilePictures = { original: string[] };
type ProfileVideoVariant = {
  orientation: "landscape" | "portrait" | "square";
  stream_url: string;
  still_image_url_med: string;
  still_image_blurred_url: string | null;
};

type ProfileLanguage = {
  id: string;
  name: string;
  slug: string;
  code: string;
};

type ProfileInsurance = {
  name: string;
  slug: string;
};

type ProfileCategory = {
  id: string;
  name: string;
  slug: string;
  cat_type: "speciality" | "approach";
};

type ProfileApproach = {
  id: string;
  name: string;
  slug: string;
  cat_type: "approach";
  excerpt: string | null;
};

type ProfileSpeciality = {
  id: string;
  name: string;
  slug: string;
  cat_type: "specialty";
  excerpt: string | null;
};

type ProfileCity = {
  id: string;
  name: string;
  slug: string;
};

type ProfileCompact = {
  id: string;
  user_id: string;
  slug: string;
  partial_uri: string;
  city: ProfileCity;
  full_name: string;
  firstname: string;
  jobtitle: string;
  pronouns?: string;
  custom_pronouns?: string;
  professional_statement: string | null;
  is_available: boolean;
  active_in_eap_program: boolean;
  showPrice: boolean;
  price_amount: number;
  price_currency: string;
  profile_picture_url: string;
  has_active_video: boolean;
  supports_video_therapy: boolean;
  supports_in_person_therapy: boolean;
  supports_text_therapy: boolean;
  categories: ProfileCategory[];
  top_categories: ProfileCategory[];
  response_time: number;
  month_year_started: string; // YYYY-MM-DD
  lat: number;
  lng: number;
};
type Profile = {
  user: ProfileUser;
  link: string;
  profile_picture_og_url: string;
  approach: string | null;
  about: string | null;
  lat: number;
  lng: number;
  street: string;
  number: string; // street number
  zip: string;
  district?: string;
  pictures: ProfilePictures;
  primary_session_offer?: PrimarySessionOffer;
  sliding_scale_session_offer?: SlidingScaleSessionOffer;
  free_initial_session_offer?: FreeInitialSessionOffer;
  secondary_session_offers: SecondarySessionOffer[];
  showActivity: boolean;
  showSessions: boolean;
  showJoined: boolean;
  allowSearchIndexing: boolean;
  sessions_count: number;
  upcoming_availability?: ProfileAvailability;
  education: string | null;
  experience: string | null;
  profile_approved_at: string;
  languages: ProfileLanguage[];
  insurances: ProfileInsurance[];
  has_limited_profile: boolean;
  calendar_enabled: boolean;
  active_videos: ProfileVideoVariant[];
  is_premium: boolean;
  partial_uri: string;
  status_message: string;
  appointment_duration: number;
  external_links: {
    id: string;
    url: string;
    url_type: string;
  }[];
  courses?: ResourceCourse[];
  events?: ResourceEvent[];
  publications?: ResourcePublication[];
  podcast?: ResourcePodcast;
  approaches?: ProfileApproach[];
  specialities?: ProfileSpeciality[];
  country: string;
} & ProfileCompact;

// Profile with additional fields for the logged in user, primarily used for editing the profile
type MyProfile = {
  gender: string;
  identities: string[];
  diplomas: Attachment[];
  currency: string;
  country: string;
  country_code: string;
  gallery_photos_data: Attachment[];
  profile_photo_data: Attachment;
  timezone: string;
  base_profile_texts: ProfileTexts;
  current_secondary_profile_text_language: string;
  secondary_language_profile_texts: ProfileTexts[];
  // Eventually, these should be moved up, and probably replace "categories" in the profile / compact type
  specialities: Category[];
  approaches: Category[];
} & Profile;

type ProfileTexts = {
  jobtitle: string;
  lang: string;
  professional_statement: string;
  about: string;
  approach: string;
  education: string;
  experience: string;
};

type ResourceCourse = {
  id: string;
  title: string;
  link: string;
  image: Attachment;
};

type ResourceEvent = {
  id: string;
  title: string;
  starts_at: string;
  link: string;
  image: Attachment;
};

type ResourcePublication = {
  id: string;
  title: string;
  description: string;
  link: string;
  image: Attachment;
};

type ResourcePodcast = {
  link: string;
};

export type {
  Profile,
  ProfileAvailability,
  ProfileAvailabilitySlots,
  ProfileUser,
  ProfileVideoVariant,
  ProfileCompact,
  MyProfile,
  PrimarySessionOffer,
  SecondarySessionOffer,
  ResourceCourse,
  ResourceEvent,
  ResourcePublication,
};

export { SessionOfferType };
