
import { Badge, Flex } from "@radix-ui/themes";
import styles from "./ProfileIconBadges.module.scss";

type Badge = {
  icon: JSX.Element;
  text?: string;
};

type ProfileIconBadgesProps = {
  badges: Badge[];
}

function ProfileIconBadges({ badges }: ProfileIconBadgesProps) {
  return badges.length > 0 && (
    <Flex direction={"column"} gap={"3"} mb={"5"}>
      {badges.map((badge, index) => (
        <Badge
          className={styles.badge}
          key={index}
          size={"3"}
          radius={"full"}
        >
          {badge.icon}
          {badge.text}
        </Badge>
      ))}
    </Flex>
  );
}

export default ProfileIconBadges;
