import { TextV2 } from "@/design-system/components/text/TextV2";
import { useTranslations } from "next-intl";

type ProfileResponseTimeMessageProps = {
  responseTime: number;
};

function ProfileResponseTimeMessage(props: ProfileResponseTimeMessageProps) {
  const responseTimeKey = getResponseTimeKey(props.responseTime);
  const t = useTranslations("Search.directory_card.response_times");
  return <TextV2 textStyle={"Body M"}>{t(responseTimeKey)}</TextV2>;
}

export default ProfileResponseTimeMessage;

function getResponseTimeKey(responseTime: number) {
  if (responseTime < 3600 * 2) {
    return "within_two_hours";
  } else if (responseTime < 3600 * 4) {
    return "within_four_hours";
  } else if (responseTime < 3600 * 8) {
    return "within_eight_hours";
  } else if (responseTime < 3600 * 12) {
    return "within_twelve_hours";
  } else if (responseTime > 3600 * 24) {
    return "after_one_day";
  } else {
    return "within_one_day";
  }
}