import { Flex, Link } from "@radix-ui/themes";
import classNames from "classnames";
import { ChevronRight, MoveUpRight } from "lucide-react";
import { TextV2 } from "../text/TextV2";
import styles from "./LinkIcon.module.scss";

type IconType = "arrow" | "chevron";

type LinkIconProps = {
  iconType: IconType;
  text: string;
  rel?: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

function LinkIcon({
  iconType = "chevron",
  text,
  rel,
  href,
  onClick,
}: LinkIconProps) {
  return (
    <Flex className={styles.linkContainer} align={"center"} onClick={onClick}>
      <Link className={styles.link} target={"_blank"} rel={rel} href={href}>
        <TextV2 textStyle={"Headline XS"} weightStyle={"medium"}>
          {text}
        </TextV2>
      </Link>

      {iconType === "chevron" ? (
        <ChevronRight
          className={classNames(styles.linkIcon, styles.hasSmallMarginLeft)}
          size={19}
        />
      ) : (
        <MoveUpRight className={styles.linkIcon} size={17} />
      )}
    </Flex>
  );
}

export { LinkIcon };
