import { profile as api } from "@/api/entities";
import { Item, RadioGroup, Root } from "@radix-ui/react-radio-group";
import { AlertDialog, Box, Flex, Text, Tooltip } from "@radix-ui/themes";
import moment from "moment";
import { useTranslations } from "next-intl";
import React, { ChangeEvent, FormEvent, useRef } from "react";
import { TimeSlot, UpcomingAvailabilityByDay } from "@/utils/TimeUtils";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import styles from "./ProfileContactMessage.module.scss";

export type ProfileContactMessage = {
  profile: api.Profile;
  upcomingAvailabilitySlots: UpcomingAvailabilityByDay;
  isBusy: boolean;
  message: string;
  setMessage: (message: string) => void;
  selectedDatetime: string | undefined;
  setSelectedDatetime: (datetime: string) => void;
  showEAPWarning: boolean;
  onSend: (
    message: string,
    appointmentUTCTimestamp: number | undefined,
  ) => void;
};

export function ProfileContactMessage({
  profile,
  upcomingAvailabilitySlots,
  isBusy,
  message,
  setMessage,
  selectedDatetime,
  setSelectedDatetime,
  showEAPWarning,
  onSend,
}: ProfileContactMessage) {
  const t = useTranslations("Profile");
  const formRef = useRef<HTMLFormElement | null>(null);
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    setMessage(event.target.value);
  };

  const triggerSubmit = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSend(
      message,
      selectedDatetime ? moment(selectedDatetime).utc().valueOf() : undefined,
    );
  };

  const sendingDisabled = message.trim() === "";
  return (
    <Flex
      className={styles.container}
      direction={"column"}
      overflowY={"auto"}
      height={"100%"}
      asChild
    >
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <Flex className={styles.scrollableFormContent} direction={"column"}>
          <Box pb={"5"}>
            <Text>{t("contact.message")} <span className={styles.messageRequired}>{t("contact.required")}</span></Text>
          </Box>
          <textarea
            className={styles.messageArea}
            rows={7}
            value={message}
            onChange={handleInputChange}
            placeholder={t("contact.message_placeholder")}
          />
          {upcomingAvailabilitySlots.length > 0 && (
            <Flex direction={"column"}>
              {(selectedDatetime && (
                <Box mt={"5"} mb={"2"} mx={"2"}>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: t("contact.selected_appointment_slot", {
                        date: `<b>${moment(selectedDatetime).format("MMMM D, YYYY")}</b>`,
                        time: `<b>${moment(selectedDatetime).format("HH:mm")}</b>`,
                      }),
                    }}
                  ></Text>
                </Box>
              )) || (
                <Box mt={"5"} mb={"2"} mx={"2"}>
                  <Text>{t("contact.select_appointment_slot")}</Text>
                </Box>
              )}
              <ProfileContentAppointements
                availabilityEntries={upcomingAvailabilitySlots}
                selectedDatetime={selectedDatetime}
                setSelectedDatetime={setSelectedDatetime}
              />
            </Flex>
          )}
        </Flex>
        <Flex
          direction={"column"}
          position={"fixed"}
          bottom={"0"}
          align={"center"}
          gap={"3"}
          py={"3"}
          px={"4"}
          width={"100%"}
          style={{
            background: "linear-gradient(to top, white 50%, transparent 100%",
          }}
        >
          <Tooltip
            content={t("contact.send_button_disabled_tooltip", {
              name: profile.firstname,
            })}
          >
            {/* Wrapper required so pointer events work on the tooltip */}
            <Flex align={"center"} justify={"center"} width={"100%"}>
              {showEAPWarning ? (
                <SendMessageWithEapWarning
                  profile={profile}
                  isBusy={isBusy}
                  disabled={sendingDisabled}
                  triggerSubmit={triggerSubmit}
                />
              ) : (
                <ButtonV2
                  className={styles.button}
                  loading={isBusy}
                  variant={"solid"}
                  disabled={sendingDisabled}
                  state={sendingDisabled ? "disabled" : "normal"}
                  size={"4"}
                  type={"submit"}
                  style={{
                    background: sendingDisabled ? "var(--gray-5)" : undefined,
                  }}
                >
                  {t("message_therapist", {
                    name: profile.firstname,
                  })}
                </ButtonV2>
              )}
            </Flex>
          </Tooltip>
        </Flex>
      </form>
    </Flex>
  );
}

function SendMessageWithEapWarning({
  profile,
  isBusy,
  disabled,
  triggerSubmit,
}: {
  profile: api.Profile;
  isBusy: boolean;
  disabled: boolean;
  triggerSubmit: () => void;
}) {
  const t = useTranslations("Profile");
  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger>
        <ButtonV2
          loading={isBusy}
          variant={"solid"}
          disabled={disabled}
          state={disabled ? "disabled" : "normal"}
          size={"4"}
          type={"button"}
          style={{ background: disabled ? "var(--gray-5)" : undefined }}
        >
          {t("send_message", {
            name: profile.firstname,
          })}
        </ButtonV2>
      </AlertDialog.Trigger>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>
          {t("contact.eap_warning_alert.title")}
        </AlertDialog.Title>
        <AlertDialog.Description>
          <Flex direction={"column"} gap={"3"}>
            <Text>
              {t("contact.eap_warning_alert.message_1", {
                name: profile.firstname,
              })}
            </Text>
            <Text>
              {t("contact.eap_warning_alert.message_2", {
                name: profile.firstname,
              })}
            </Text>
          </Flex>
        </AlertDialog.Description>

        <Flex gap="3" mt="5" justify="end">
          <AlertDialog.Cancel>
            <ButtonV2 variant="soft" color="gray" type={"button"}>
              {t("contact.eap_warning_alert.cancel")}
            </ButtonV2>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <ButtonV2 variant="solid" color="mint" onClick={triggerSubmit}>
              {t("contact.eap_warning_alert.send")}
            </ButtonV2>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}

type ProfileContentAppointementsProps = {
  availabilityEntries: UpcomingAvailabilityByDay;
  selectedDatetime?: string;
  setSelectedDatetime: (datetime: string) => void;
};

function ProfileContentAppointements(props: ProfileContentAppointementsProps) {
  // The availability radio group is not in a form control because radix forms cannot
  // validate the radix radio group at the time this was created it returns a "setCustomValidity
  // is not a function" error, so we manage this outside the form
  return (
    <Root
      className={styles.availabilityRadioGroup}
      value={props.selectedDatetime}
      onValueChange={props.setSelectedDatetime}
    >
      <div>
        {props.availabilityEntries.map(([isoDate, slots]) => {
          const date = moment(isoDate);
          const dayOfWeek = date.format("ddd"); // Format for the day of the week as a word
          const formattedDate = date.format("DD.MM"); // Format for "DD.MM"
          return (
            <div key={isoDate}>
              <Flex gap={"1"} align={"center"} my={"2"}>
                <Text weight={"medium"}>{dayOfWeek}</Text>
                <span>
                  <Text
                    className={styles.appointmentPickerDate}
                    weight={"medium"}
                    color={"gray"}
                  >
                    {formattedDate}
                  </Text>
                </span>
                <hr className={styles.appointmentPickerDateRule} />
              </Flex>
              <Flex
                wrap={"wrap"}
                gap={"2"}
                align={"center"}
                mb={"5"}
                overflowX={"auto"}
              >
                {slots.map((slot: TimeSlot) => {
                  return (
                    <Item
                      className={styles.item}
                      key={slot.startUTCFormatted}
                      value={slot.startUTCFormatted}
                    >
                      <RadioGroup
                        className={styles.appointmentIndicator}
                        asChild
                      >
                        <Text>{slot.startLocalFormatted}</Text>
                      </RadioGroup>
                    </Item>
                  );
                })}
              </Flex>
            </div>
          );
        })}
      </div>
    </Root>
  );
}
