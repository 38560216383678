import { memo } from "react";

type PodcastPlayerEmbedProps = {
  src: string;
};

// Memoized component to render the podcast embed
const PodcastPlayerEmbed: React.FC<PodcastPlayerEmbedProps> = memo(({ src }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: `<iframe style="border-radius:12px" src="${src}" frameBorder="0" width="100%" height="152" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" allowfullscreen loading="lazy"></iframe>`,
    }}
  />
));

PodcastPlayerEmbed.displayName = "PodcastPlayerEmbed";

export default PodcastPlayerEmbed;